<template>
  <div>
    <b-modal id="modal-prevent-closing" ref="modal" :title="$t('confirmationDialog.print_barcode')" centered no-close-on-backdrop hide-footer
      @show="showModal" @hidden="resetModal" @hide="resetModal">
      <b-form-group>
        <b-form-radio-group id="radio-group-1" v-model="selectedRadio" :options="radioOptions"
          name="radio-options"></b-form-radio-group>
      </b-form-group>
      <div class="custom-calender-wrapper">
        <b-form-group v-if="selectedRadio === 'filtered'">
        <label>{{ $t('confirmationDialog.select_daterange') }} : </label>
        <date-range-picker ref="picker" opens="left" :ranges="ranges()" :locale-data="{
          direction: 'ltr',
          format: 'MM-DD-yyyy',
          separator: ' - ',
          applyLabel: 'Apply',
          cancelLabel: 'Cancel',
          weekLabel: 'W',
          customRangeLabel: 'Custom Range',
          daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
          monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          firstDay: 0
        }" @update="onFilter" v-model="printFilterDates" :alwaysShowCalendars="false"
          controlContainerClass="noneBorder" style="min-width:200px;">
          <template v-slot:input="picker">
            <b-form-input type="text" hide-details="auto" :label="$t('invoice.createForm.date')"
              append-icon="mdi-calendar" readonly elevation="0" height="40" outlined
              :value="setDates(picker)"></b-form-input>
          </template>
        </date-range-picker>
      </b-form-group>
      </div>
      <div class="d-block text-center">
        <b-button class="mt-4 btn custom-btn custom-danger" variant="outline-danger" @click="resetModal">
          {{ $t("confirmationDialog.cancel") }}
        </b-button>

        <b-button class="ml-2 mt-4 btn custom-btn pattern-btn" @click="handleOk"><span>{{ $t("confirmationDialog.go_to_print") }}</span>
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
import { required } from "vuelidate/lib/validators";
import { showFailure, showSuccess } from "@/eventbus/action";
import $ from "jquery";
import EventBus from "@/eventbus";
import { mounted } from "vue-barcode";

export default {
  props: {
    partIds: {
      type: String | Array,
      required
    }, startDate: {
      type: Date,
      required
    }, endDate: {
      type: Date,
      required
    }, dialog: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      printFilterDates: {
        startDate: this.startDate,
        endDate: this.endDate,
      },
      selectedRadio: null,
      radioOptions: [
        { text: 'Print All Selected Parts', value: 'selected' },
        { text: 'Print All Filtered Parts', value: 'filtered' },
      ]
    }
  },
  watch: {
    dialog(val) {
      if (val) {
        this.showModal();
      } else {
        this.hideModal();
      }
    },
    partIds(val) {
      if (val.length >= 1) {
        this.radioOptions = [{ text: 'Print All Selected Parts', value: 'selected' }, { text: 'Print All Filtered Parts', value: 'filtered' }];
        this.selectedRadio = 'selected';
      } else {
        this.radioOptions = [{ text: 'Print All Filtered Parts', value: 'filtered' }];
        this.selectedRadio = 'filtered';
      }
    }
  },
  methods: {
    showModal() {
      this.$refs["modal"].show();
    },
    hideModal() {
      EventBus.$emit("resetDialog");
      this.$refs["modal"].hide();
    },

    resetModal() {
      this.hideModal();
    },

    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault()
      // Trigger submit handler
      if (this.selectedRadio == 'selected') {
        this.$router.push({ name: "Print All Barcode", params: { ids: this.partIds, type: 'part_id' } });
      } else {
        this.$router.push({ name: "Print All Barcode", params: { ids: this.printFilterDates, type: 'filtered_date' } });
      }
    },
    
    async onFilter() {
      this.$store.commit("setLoader", true);
      // this.table.clear().destroy();
      // this.getPartList();
      this.$store.commit("setLoader", false);
    },

    setDates(picker) {
      let startDate = (picker.startDate ? moment(picker.startDate).format('MM-DD-YYYY') + ' - ' : '');
      let endDate = (picker.endDate ? moment(picker.endDate).format('MM-DD-YYYY') : '');
      return startDate + endDate;
    },
    ranges() {
      let today = new Date()
      today.setHours(0, 0, 0, 0);

      let yesterday = new Date()
      yesterday.setDate(today.getDate() - 1)
      yesterday.setHours(0, 0, 0, 0);
      var y = today.getFullYear(), m = today.getMonth();
      var thisMonthStart = new Date(y, m, 1);
      let thisMonthEnd = new Date(y, m + 1, 0, 11, 59, 59, 999);

      return {
        'Today': [today, today],
        'Yesterday': [yesterday, yesterday],
        'This month': [thisMonthStart, thisMonthEnd],
        'Last 7 Days': [new Date(y, m, today.getDate() - 6), today],
        'Last 30 Days': [new Date(y, m, today.getDate() - 29), today],
        'This year': [new Date(y, 0, 1), new Date(y, 11, 31)],
        'Last month': [new Date(y, m - 1, 1), new Date(y, m, 0)],
      }
    },
  }
}
</script>
